<template>
  <v-card outlined flat :loading="ordersTableLoading">
    <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
      <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
        <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-notebook-outline</v-icon>
        <span>{{`Заявка № ${order.number}`}}</span>
        <v-chip v-if="$vuetify.breakpoint.smAndUp" :color="stateColor" text-color="white" small class="ml-4 mb-1">{{order.statename}}</v-chip>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="#e31e25" text dark x-small @click="close">
        <v-icon left dark>mdi-window-close</v-icon>
        Закрыть
      </v-btn>
    </v-app-bar>
    <v-divider />
    <div :style="{ display: 'flex', justifyContent: 'center' }">
      <div
        :style="{
          maxWidth: '800px',
          marginTop: '16px',
          marginBottom: '16px',
          padding: '16px',
          border: '1px solid lightgray'
        }"
      >
        <div :class="$vuetify.breakpoint.mdAndUp ? 'px-4 py-3' : 'px-2 py-3'">
          <v-icon left color="#ef7d1a" size="24" class="pb-1">mdi-information-outline</v-icon>
          <span class="text-body-1 font-weight-half-medium mx-1">Основная информация</span>
        </div>
        <div :class="$vuetify.breakpoint.mdAndUp ? 'mx-15' : 'mx-7'">
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Наименование курса
            </span>
            <p class="text-body-1">
              {{order.course.name || 'Не указано'}}
            </p>
          </div>
          <div v-if="$vuetify.breakpoint.xsOnly">
            <span class="text-body-1 font-weight-half-medium">
              Состояние: 
            </span>
            <span class="text-body-1">
              {{order.statename}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Дата проведения: 
            </span>
            <span class="text-body-1">
              {{`с ${order.course.beginDate} по ${order.course.endDate}`}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Дата подачи: 
            </span>
            <span class="text-body-1">
              {{order.date || 'Нет данных'}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Результат тестирования: 
            </span>
            <span class="text-body-1">
              {{order.testResult || 'Нет данных'}}
            </span>
          </div>
        </div>


        <div :class="$vuetify.breakpoint.mdAndUp ? 'px-4 py-3' : 'px-2 py-3'">
          <v-icon left color="#d7b56d" size="24" class="pb-1">mdi-file-document-outline</v-icon>
          <span class="text-body-1 font-weight-half-medium mx-1">Документы</span>
          <p 
            v-if="order.statename === 'Черновик'" 
            class="text-body-2 mx-9 pt-2"
          >
            Пожалуйста, приложите необходимые документы к заявке из списка
          </p>
        </div>
        <div :class="$vuetify.breakpoint.mdAndUp ? 'mx-15 pb-3' : 'mx-7 pb-3'">
          <order-documents 
            :order="order" 
            :documents="documents"
            :ordersTableLoading="ordersTableLoading"
            v-on:add-document="$emit('add-document')"
            v-on:select-document="selectDocument"
            v-on:remove-document="removeDocument"
          ></order-documents>
        </div>

        <div class="mx-4 pt-3 pb-5 text-center" v-if="order.statename === 'Черновик'">
          <v-btn
            color="#ef7d1a"
            class="ma-2"
            small
            text
            :disabled="ordersTableLoading"
            @click="$emit('change-order-state', order, 'Новая')"
          >
            Отправить заявку
            <v-icon right dark>mdi-arrow-right-thick</v-icon>
          </v-btn>
          <v-btn
            color="#e31e25"
            class="ma-2"
            small
            text
            :disabled="ordersTableLoading"        
            @click="$emit('delete-order', order.id)"
          >
            <v-icon left dark> mdi-window-close </v-icon>
            Удалить заявку
          </v-btn>
        </div>
        <div class="mx-4 pt-3 pb-5 text-center" v-else-if="order.statename === 'На рассмотрении' && order.course.testLink">
          <v-btn
            color="#7690c9"
            class="ma-2"
            small
            text
            :disabled="ordersTableLoading"        
            :href="order.course.testLink"
            target="_blank"
          >
            <v-icon left dark> mdi-arrow-right-thick </v-icon>
            Перейти к тестированию
          </v-btn>
        </div>
        <div class="mx-4 pt-3 pb-5 text-center" v-else-if="order.statename === 'Новая'">
          <v-btn
            text
            :disabled="ordersTableLoading"
            color="#af955c"
            class="ma-2"
            small
            @click="$emit('change-order-state', order, 'Черновик')"
          >
            <v-icon left dark>mdi-arrow-left-thick</v-icon>
            Вернуть на редактирование
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import OrderDocumentsVue from './OrderDocuments.vue';
export default {
  name: "OrderCard",
  props: {
    order: Object,
    documents: Array,
    isEvent: Function,
    ordersTableLoading: Boolean
  },
  components: {
    'order-documents': OrderDocumentsVue
  },
  computed: {
    fullName() {
      return `Заявка № ${this.order.number} на курс ${this.order.course.fName}`;
    },
    stateColor() {
      if (this.order.statename === 'Черновик') return 'grey';
      else if (this.order.statename === 'Новая') return '#ef7d1a';
      else if (this.order.statename === 'На рассмотрении') return '#00AEBA'
      else if (this.order.statename === 'Отклонена') return '#e31e25'
      else if (this.order.statename === 'Утверждена') return '#d7b56d'
      else if (this.order.statename === 'Резерв') return '#af955c'
      else return 'grey';
    },
  },
  methods: {
    selectDocument(document) {
      this.$emit('select-document', document);
    },
    removeDocument(id, orderId) {
      this.$emit('remove-document', id, orderId);
    },
    close() {
      this.$emit('close-order');
    }
  }
};
</script>