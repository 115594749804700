<template>
  <div v-if="staffInfo" class="mb-4" v-cloak>
    <p class="text-h5 font-weight-light mt-3">Мой профиль</p>
    <v-divider></v-divider>
    
    <v-row class="mt-7" justify="center" align="center">
      <v-card
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'"
        flat
        tile
        outlined
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'">
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title
            v-if="!isGeneralEditing"
            class="text-body-1 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-account-outline</v-icon>
            <span>{{ fullName }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :color="isGeneralEditing ? '#e31e25' : '#7690c9'"
            text
            dark
            x-small
            :disabled="
              isContactsEditing ||
                isChangingPassword ||
                isWorkInformationEditing ||
                isEducationEditing
            "
            @click="generalEditButton">
            <v-icon left dark>{{isGeneralEditing ? "mdi-window-close" : "mdi-pencil"}}</v-icon>
            {{ isGeneralEditing ? "Отмена" : "Редактировать" }}
          </v-btn>
        </v-app-bar>
        <v-divider />
        <div v-if="!isGeneralEditing" class="pl-4 my-4">
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Пол:
            </span>
            <span class="text-body-1">
              {{ staffInfo.gender ? staffInfo.gender : "Не указан" }}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Дата рождения:
            </span>
            <span class="text-body-1">
              {{ concatedBirthdayInfo }}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Размер одежды:
            </span>
            <span class="text-body-1 mb-0">
              {{staffInfo.clothing_size ? staffInfo.clothing_size : "Не указан"}}
            </span>
          </div>
        </div>
        <v-form v-else class="mx-5 mt-4" v-model="isGeneralValid">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="staffInfoForEdit.lastName"
                :rules="[rules.required]"
                label="Фамилия"
                color="#af955c"
                dense
                required
                :error="!staffInfoForEdit.lastName"
                :readonly="!isGeneralEditing"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="staffInfoForEdit.firstName"
                :rules="[rules.required]"
                label="Имя"
                color="#af955c"
                dense
                required
                :error="!staffInfoForEdit.firstName"
                :readonly="!isGeneralEditing"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="staffInfoForEdit.middleName"
                label="Отчество"
                hint="Введите отчество"
                dense
                color="#af955c"
                :readonly="!isGeneralEditing"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="start" align="center">
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-select
                v-model="staffInfoForEdit.gender"
                :items="genders"
                :rules="[rules.required]"
                label="Пол"
                color="#af955c"
                dense
                :error="!staffInfoForEdit.gender"
                required
                :readonly="!isGeneralEditing"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                v-model="staffInfoForEdit.birthdayDate"
                label="Дата рождения"
                hint="Укажите дату рождения"
                color="#af955c"
                dense
                type="date"
                min="1900-01-01"
                max="2200-12-31"
                :value="staffInfoForEdit.birthdayDate"
                :error="!staffInfoForEdit.birthdayDate"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                v-model="staffInfoForEdit.clothing_size"
                :rules="[rules.required]"
                label="Размер одежды"
                hint="Введите размер одежды"
                color="#af955c"
                :error="!staffInfoForEdit.clothing_size"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-5" justify="center" align="center">
            <v-btn
              text
              :disabled="!isGeneralValid"
              :dark="isGeneralValid"
              color="#af955c"
              @click="save"
            >
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
    
    <v-row class="mt-8" justify="center" align="center">
      <v-card
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'"
        flat
        tile
        outlined
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
      >
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-map-marker-outline</v-icon>
            <span>Контактная информация</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :color="isContactsEditing ? '#e31e25' : '#7690c9'"
            text
            dark
            x-small
            :disabled="
              isGeneralEditing ||
                isChangingPassword ||
                isWorkInformationEditing ||
                isEducationEditing
            "
            @click="contactsEditButton">
            <v-icon left dark>{{isContactsEditing ? "mdi-window-close" : "mdi-pencil"}}</v-icon>
            {{ isContactsEditing ? "Отмена" : "Редактировать" }}
          </v-btn>
        </v-app-bar>
        <v-divider />
        
        <div v-if="!isContactsEditing" class="pl-4 my-4">
          <span class="text-body-1 font-weight-half-medium">Адрес фактического проживания</span>
          <p class="text-body-1">
            {{ staffFullFactAddress }}
          </p>
          <span class="text-body-1 font-weight-half-medium">Адрес регистрации</span>
          <p class="text-body-1">
            {{ staffFullRegAddress }}
          </p>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              E-mail:
            </span>
            <span class="text-body-1 mb-0">
              {{
                staffInfo.contacts && staffInfo.contacts.email
                  ? staffInfo.contacts.email
                  : "Не указан"
              }}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Телефон:
            </span>
            <span class="text-body-1">
              {{
                staffInfo.contacts && staffInfo.contacts.phone
                  ? staffInfo.contacts.phone
                  : "Не указан"
              }}
            </span>
          </div>
        </div>
        
        <v-form
          v-show="isContactsEditing"
          class="mx-5"
          v-model="isContactsValid">
          <p class="text-overline mt-3 ml-6">Адрес регистрации</p>
          <v-divider inset></v-divider>
          <v-row class="mt-4" justify="center" align="center">
            <v-col cols="12" sm="10" md="12" lg="10" xl="8">
              <v-autocomplete
                v-model="staffInfoForEdit.contacts.address_reg"
                label="Адрес регистрации"
                hint="Введите адрес регистрации"
                :loading="isSearchLoading"
                :items="regAddressItems"
                item-text="fullAddress"
                item-value="id"
                no-filter
                :search-input.sync="regAddressSearch"
                hide-no-data
                hide-details
                :rules="[rules.required]"
                :error="!staffInfoForEdit.contacts.address_reg"
                required
                dense
                color="#af955c"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="4" sm="4" md="3" lg="2" xl="2">
              <v-text-field
                v-model="staffInfoForEdit.contacts.address_reg_house"
                :rules="[rules.required]"
                label="Дом"
                hint="Введите номер дома"
                color="#af955c"
                :error="!staffInfoForEdit.contacts.address_reg_house"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2">
              <v-text-field
                v-model="staffInfoForEdit.contacts.address_reg_building"
                label="Корпус"
                hint="Введите корпус/строение (при наличии)"
                color="#af955c"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2">
              <v-text-field
                v-model="staffInfoForEdit.contacts.address_reg_flat"
                label="Квартира"
                hint="Введите номер квартиры (при наличии)"
                color="#af955c"
              ></v-text-field>
            </v-col>
          </v-row>
          <p class="text-overline mt-3 ml-6">Фактический адрес проживания</p>
          <v-divider inset></v-divider>
          <v-row class="mt-4" justify="center" align="center">
            <v-col cols="12" sm="10" md="12" lg="10" xl="8">
              <v-autocomplete
                v-model="staffInfoForEdit.contacts.address_fact"
                label="Фактический адрес проживания"
                hint="Введите фактический адрес проживания"
                :loading="isSearchLoading"
                :items="factAddressItems"
                item-text="fullAddress"
                item-value="id"
                no-filter
                :search-input.sync="factAddressSearch"
                hide-no-data
                hide-details
                :rules="[rules.required]"
                :error="!staffInfoForEdit.contacts.address_fact"
                required
                dense
                color="#af955c"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="4" sm="4" md="3" lg="2" xl="2">
              <v-text-field
                v-model="staffInfoForEdit.contacts.address_fact_house"
                :rules="[rules.required]"
                label="Дом"
                hint="Введите номер дома"
                color="#af955c"
                :error="!staffInfoForEdit.contacts.address_fact_house"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2">
              <v-text-field
                v-model="staffInfoForEdit.contacts.address_fact_building"
                label="Корпус"
                hint="Введите корпус/строение (при наличии)"
                color="#af955c"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="3" md="3" lg="2" xl="2">
              <v-text-field
                v-model="staffInfoForEdit.contacts.address_fact_flat"
                label="Квартира"
                hint="Введите номер квартиры (при наличии)"
                color="#af955c"
              ></v-text-field>
            </v-col>
          </v-row>
          <p class="text-overline mt-3 ml-6">Контакты</p>
          <v-divider inset></v-divider>
          <v-row class="mt-4" justify="start" align="center">
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                v-model="staffInfoForEdit.contacts.email"
                :rules="[rules.required, rules.email]"
                label="E-mail"
                hint="Введите e-mail адрес"
                color="#af955c"
                dense
                :error="!staffInfoForEdit.contacts.email"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                v-model="staffInfoForEdit.contacts.phone"
                :rules="[rules.required, rules.phone]"
                label="Телефон"
                hint="Введите номер телефона"
                v-mask="'+7(###)###-##-##'"
                color="#af955c"
                dense
                type="phone"
                :error="!staffInfoForEdit.contacts.phone"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="isContactsEditing"
            class="my-5"
            justify="center"
            align="center">
            <v-btn
              text
              :disabled="!isContactsValid"
              :dark="isContactsValid"
              color="#af955c"
              @click="save">
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
    
    <v-row class="mt-8" justify="center" align="center">
      <v-card
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'"
        flat
        tile
        outlined
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
      >
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-school-outline</v-icon>
            <span>Образование</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :color="isEducationEditing ? '#e31e25' : '#7690c9'"
            text
            dark
            x-small
            :disabled="
              isGeneralEditing ||
                isChangingPassword ||
                isWorkInformationEditing ||
                isContactsEditing
            "
            @click="educationEditButton">
            <v-icon left dark>{{isEducationEditing ? "mdi-window-close" : "mdi-pencil"}}</v-icon>
            {{ isEducationEditing ? "Отмена" : "Редактировать" }}
          </v-btn>
        </v-app-bar>
        <v-divider />
        
        <div v-if="!isEducationEditing" class="pl-4 my-4">
          <span class="text-body-1 font-weight-half-medium">Учебное учреждение</span>
          <p class="text-body-1">
            {{
              staffInfo.education && staffInfo.education.name
                ? staffInfo.education.name
                : "Не указан"
            }}
          </p>
          <span class="text-body-1 font-weight-half-medium">Специальность</span>
          <p class="text-body-1">
            {{
              staffInfo.education && staffInfo.education.spec
                ? staffInfo.education.spec
                : "Не указан"
            }}
          </p>
        </div>
        
        <v-form
          v-show="isEducationEditing"
          class="mx-5 mt-4"
          v-model="isEducationValid">
          <v-row justify="start" align="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                v-model="staffInfoForEdit.education.name"
                :rules="[rules.required]"
                label="Учебное учреждение"
                hint="Укажите учебное учреждение (без сокращения)"
                color="#af955c"
                dense
                :error="!staffInfoForEdit.education.name"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="start" align="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                v-model="staffInfoForEdit.education.spec"
                :rules="[rules.required]"
                label="Специльность"
                hint="Укажите специальность"
                color="#af955c"
                dense
                :error="!staffInfoForEdit.education.spec"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="isEducationEditing"
            class="my-5"
            justify="center"
            align="center">
            <v-btn
              text
              :disabled="!isEducationValid"
              :dark="isEducationValid"
              color="#af955c"
              @click="save">
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>

    <v-row class="mt-8" justify="center" align="center">
      <v-card
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'"
        flat
        tile
        outlined
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
      >
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-clipboard-account-outline</v-icon>
            <span>Желаемая смена</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :color="isWorkInformationEditing ? '#e31e25' : '#7690c9'"
            text
            dark
            x-small
            :disabled="
              isContactsEditing ||
                isGeneralEditing ||
                isChangingPassword ||
                isEducationEditing
            "
            @click="workInformationEditButton">
            <v-icon left dark>{{isWorkInformationEditing ? "mdi-window-close" : "mdi-pencil"}}</v-icon>
            {{ isWorkInformationEditing ? "Отмена" : "Редактировать" }}
          </v-btn>
        </v-app-bar>
        <v-divider />
        
        <div v-if="!isWorkInformationEditing" class="pl-4 my-4">
          
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Смена:
            </span>
            <span class="text-body-1 mb-0">
              {{staffInfo.campShiftText ? staffInfo.campShiftText : "Не указан"}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Должность:
            </span>
            <span class="text-body-1 mb-0">
              {{staffInfo.positionText ? staffInfo.positionText : "Не указана"}}
            </span>
          </div>
        </div>
        
        <v-form
          v-show="isWorkInformationEditing"
          class="mx-5 mt-4"
          v-model="isWorkInformationValid">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-select
                v-model="staffInfoForEdit.campShift"
                label="Смена"
                hint="Выберите смену из списка"
                color="#af955c"
                dense
                :items="campShifts"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                :error="!staffInfoForEdit.campShift"
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-select
                v-model="staffInfoForEdit.position"
                label="Должность"
                hint="Выберите должность из списка"
                color="#af955c"
                dense
                :items="positions"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                :error="!staffInfoForEdit.position"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            v-if="isWorkInformationEditing"
            class="my-5"
            justify="center"
            align="center">
            <v-btn
              text
              :disabled="!isWorkInformationValid"
              :dark="isWorkInformationValid"
              color="#af955c"
              @click="save">
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>

    <v-row class="mt-8" justify="center" align="center">
      <v-card
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'"
        flat
        tile
        outlined
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'">
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-lock-outline</v-icon>
            <span>Безопасность</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :color="isChangingPassword ? '#e31e25' : '#7690c9'"
            :disabled="
              isContactsEditing || isGeneralEditing || isEducationEditing || isWorkInformationEditing 
            "
            class="ma-2"
            text
            dark
            x-small
            @click="passwordButton">
            <v-icon left dark>{{isChangingPassword ? "mdi-window-close" : "mdi-lock"}}</v-icon>
            {{ isChangingPassword ? "Отмена" : "Изменить пароль" }}
          </v-btn>
        </v-app-bar>
        <v-divider />
        
        <v-form
          v-if="isChangingPassword"
          class="mt-4 mb-4"
          v-model="isPasswordValid">
          <v-row class="mt-4" justify="center" align="center">
            <v-col cols="10" sm="10" md="5" lg="4" xl="4">
              <v-text-field
                v-model="security.password"
                color="#af955c"
                :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.minPassword]"
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Новый пароль"
                hint="Введите новый пароль"
                dense
                required
                :error="!security.password"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="10" md="5" lg="4" xl="4">
              <v-text-field
                v-model="security.passwordRepeated"
                color="#af955c"
                :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[
                  rules.required,
                  rules.passwordMatch(
                    security.password,
                    security.passwordRepeated),]"
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Подтверждение пароля"
                hint="Введите новый пароль еще раз"
                required
                :error="!security.passwordRepeated"
                dense
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-5" justify="center" align="center">
            <v-btn
              text
              :disabled="!isPasswordValid"
              :dark="isPasswordValid"
              color="#af955c"
              @click="changePassword">
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import _debounce from "debounce";
import gendersEnum from "../../model/genders";

export default {
  name: "Profile",
  props: {
    staffInfo: Object,
    campShifts: Array,
    positions: Array,
    rules: Object,
    formatDate: Function,
    parseDate: Function,
  },
  data: () => ({
    isGeneralEditing: false,
    isGeneralValid: false,

    isContactsEditing: false,
    isContactsValid: false,

    isWorkInformationEditing: false,
    isWorkInformationValid: false,

    isEducationEditing: false,
    isEducationValid: false,

    isPasswordValid: false,
    isPasswordVisible: false,
    isChangingPassword: false,

    dateMenu: false,
    genders: gendersEnum,

    staffInfoForEdit: {},
    security: {
      password: null,
      passwordRepeated: null,
    },

    isSearchLoading: false,
    regAddressSearch: null,
    regAddressItems: [],
    factAddressSearch: null,
    factAddressItems: [],

    token: null,
  }),
  computed: {
    staffPosition() {
      staffPositionName = "Не указан";
      staffPositionName =
        staffInfo.position && staffInfo.contacts.email
          ? staffInfo.contacts.email
          : "Не указан";
      return staffPositionName;
    },
    debouncedRegAddressSearch() {
      return _debounce(this.searchRegAddress, 700);
    },
    debouncedFactAddressSearch() {
      return _debounce(this.searchFactAddress, 700);
    },
    staffInfoStringify() {
      return JSON.stringify(this.staffInfo);
    },
    staffInfoForEditStringify() {
      return JSON.stringify(this.staffInfoForEdit);
    },
    fullName() {
      const lastName = this.staffInfo.lastName
        ? this.staffInfo.lastName
        : "Фамилия";
      const firstName = this.staffInfo.firstName
        ? this.staffInfo.firstName
        : "Имя";
      const middleName = this.staffInfo.middleName
        ? this.staffInfo.middleName
        : "";
      return lastName + " " + firstName + " " + middleName;
    },
    concatedBirthdayInfo() {
      const birthdayDate = this.staffInfo.birthdayDateFormated
        ? this.staffInfo.birthdayDateFormated
        : "Не указана";
      const age = this.staffInfo.age ? this.staffInfo.age : "неизвестно";

      return birthdayDate + " (" + age + " лет)";
    },
    staffFullRegAddress() {
      if (!this.staffInfo.contacts.addressRegText) {
        return "Не указан";
      }
      const address = this.staffInfo.contacts.addressRegText + ', ';
      const house = 'дом ' + this.staffInfo.contacts.address_reg_house;
      const building = this.staffInfo.contacts.address_reg_building ? '/' + this.staffInfo.contacts.address_reg_building : '';
      const flat = this.staffInfo.contacts.address_reg_flat ? ', квартира № ' + this.staffInfo.contacts.address_reg_flat : '';
      return address + house + building + flat;
    },
    staffFullFactAddress() {
      if (!this.staffInfo.contacts.addressFactText) {
        return "Не указан";
      }
      const address = this.staffInfo.contacts.addressFactText + ', ';
      const house = 'дом ' + this.staffInfo.contacts.address_fact_house;
      const building = this.staffInfo.contacts.address_fact_building ? '/' + this.staffInfo.contacts.address_fact_building : '';
      const flat = this.staffInfo.contacts.address_fact_flat ? ', квартира № ' + this.staffInfo.contacts.address_fact_flat : '';
      return address + house + building + flat;
    },
  },
  methods: {
    getToken() {
      let tokenElements = document.getElementsByName(
        "__RequestVerificationToken"
      );
      if (tokenElements && tokenElements.length > 0)
        this.token = tokenElements[0].value;
    },
    async setStaffInfoForEdit() {
      if (this.staffInfo) {
        this.staffInfoForEdit = JSON.parse(JSON.stringify(this.staffInfo));
        await this.$nextTick();
        this.staffInfoForEdit.birthdayDate = this.parseDate(
          this.staffInfo.birthdayDateFormated
        );

        if(this.staffInfoForEdit.contacts && this.staffInfoForEdit.contacts.fullAddressRegText !== this.regAddressSearch) {
          this.regAddressSearch = this.staffInfoForEdit.contacts.fullAddressRegText;
        }

        if(this.staffInfoForEdit.contacts && this.staffInfoForEdit.contacts.fullAddressFactText !== this.factAddressSearch) {
          this.factAddressSearch = this.staffInfoForEdit.contacts.fullAddressFactText;
        }
      }
    },
    passwordButton() {
      this.isChangingPassword = !this.isChangingPassword;
      this.security.password = null;
      this.security.passwordRepeated = null;
    },
    generalEditButton() {
      this.isGeneralEditing = !this.isGeneralEditing;
      this.setStaffInfoForEdit()
    },
    educationEditButton() {
      this.isEducationEditing = !this.isEducationEditing;
      this.setStaffInfoForEdit()
    },
    contactsEditButton() {
      this.isContactsEditing = !this.isContactsEditing;
      this.setStaffInfoForEdit();
    },
    workInformationEditButton(){
      this.isWorkInformationEditing = !this.isWorkInformationEditing;
      this.setStaffInfoForEdit();
    },
    changePassword() {
      this.$emit("change-password", this.security.password);
      this.passwordButton();
    },
    save() {
      this.$emit("save", this.staffInfoForEdit, "profile");
    },
    getCampShifts() {
      this.$emit("get-camp-shifts");
    },
    getPositions() {
      this.$emit("get-positions");
    },
    async searchAddress(searchInput) {
      this.isSearchLoading = true;
      let items = [];
      try {
        let response = await axios.get(
          "/api/getAddresses?query=" + encodeURIComponent(searchInput),
          {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        items = response.data;
      } catch (error) {
        items = [];
      } finally {
        this.isSearchLoading = false;
      }
      return items;
    },

    async searchRegAddress() {
      const items = await this.searchAddress(this.regAddressSearch) || [];
      this.regAddressItems = items;
    },
    async searchFactAddress() {
      const items = await this.searchAddress(this.factAddressSearch) || [];
      this.factAddressItems = items;
    },
  },
  watch: {
    staffInfoStringify: {
      handler(val, oldVal) {
        this.setStaffInfoForEdit();
      },
    },
    staffInfoForEditStringify: {
      handler(val, oldVal) {
        const newstaffInfo = JSON.parse(val);
        const oldstaffInfo = JSON.parse(oldVal);
        if (newstaffInfo.birthdayDate !== oldstaffInfo.birthdayDate) {
          this.staffInfoForEdit.birthdayDateFormated = this.formatDate(
            newstaffInfo.birthdayDate
          );
        }
      },
    },
    regAddressSearch(val) {
      if (val && val.length >= 3) {
        this.debouncedRegAddressSearch();
      }
    },

    factAddressSearch(val) {
      if (val && val.length >= 3) {
        this.debouncedFactAddressSearch();
      }
    },
  },
  created() {
    this.getToken();
    this.setStaffInfoForEdit();
    this.getCampShifts();
    this.getPositions();
  },
};
</script>
