<template>
  <v-row justify="center" v-if="isVisible">
    <v-dialog
      v-model="isVisible"
      persistent
      overlay-color="white"
      overlay-opacity="1"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="1300"
    >
      <v-card outlined flat :loading="isLoading">
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mbi-notebook-outline</v-icon>
            <span>Новая заявка</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#e31e25" text dark x-small @click="cancel">
            <v-icon left dark>mdi-window-close</v-icon>
            Отмена
          </v-btn>
        </v-app-bar>
        <v-divider />
        <div class="mx-5 pt-3">
          <p>
            Подать заявку на участие в программе могут лица, имеющие или получающие среднее профессиональное или высшее образование.
          </p>
          <p>
            Если число претендентов превысит количество мест на обучение по программе, то зачисление будет проходить на конкурсной основе.
          </p>
          <p>
            Мы свяжемся с вами для уточнения вашего участия или условий отбора.
          </p>
        </div>
        <v-form class="mx-5 mb-5" v-model="isOrderValid">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-autocomplete
                v-model="newOrder.course"
                :items="courses"
                label="Курс"
                hint="Выберите курс"
                color="#af955c"
                dense
                clearable
                item-text="fName"
                item-value="id"
                return-object
                no-data-text="Нет подходящих курсов"
                required
                
                :rules="[rules.required]"
                :error="!newOrder.course"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pt-3 pb-5" justify="center" align="center">
            <v-btn
              text
              :disabled="!isOrderValid || isLoading || isEmptyData" 
              :dark="isOrderValid && !isLoading"
              color="#af955c"
              @click="save"
            >
              Далее
              <v-icon right dark>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios';
export default {
  name: "OrderAdd",
  props: {
    isVisible: Boolean,
    rules: Object
  },
  data() {
    return {
      isOrderValid: false,
      isLoading: false,
      courses: [],
      newOrder: {
        course: null
      }
    };
  },
  computed: {
    isEmptyData(){
      return  (this.course && this.course.length === 0);
    },
    newOrderStringify() {
      return JSON.stringify(this.newOrder);
    }
  },
  methods: {
    async getCourses() {
        this.isLoading = true;
        try {
          let response = await axios.get(`/api/getQualsCourses`, {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          });
          this.courses = response.data;
        }
        catch (error) {
          console.error(error);
          this.courses = [];
        }
        finally {
          if (this.newOrder.course && !this.courses.find(d => d.id === this.newOrder.course.id)){
            this.newOrder.course = null;
          }          
          this.isLoading = false;
        }
    },
    setQueryData(courseId){
      if(courseId ){
        this.newOrder.course = {
          id: courseId,
        }
      }      
    },
    save() {
      if(!this.isLoading) {
        this.isLoading = true
        this.$emit('save', this.newOrder)
      }
    },
    cancel() {
      this.newOrder = {
        course: null
      }
      this.$emit('close');
    },
    async getData() {
      this.isLoading = true;
      await this.getCourses();
      this.isLoading = false;
    }
  },
  watch: {
    newOrderStringify: {
      handler() {
        if(this.courses.length === 0 ) {
          this.getCourses();
        }
      }
    }
  },
  created() {
    this.getData();
    this.setQueryData(
      this.$route.query.courseid);
  }
};
</script>